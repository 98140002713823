<div class="area-range" [style.height.px]="height" [style.width.px]="width-2"
  [style.backgroundColor]="rangeStyle.backgroundColor" [style.borderColor]="rangeStyle.borderColor" matTooltip="{{tooltipRangeTemplate}}" matTooltipPosition="below">
  <div class="range-text">
    <div *ngIf="eventRecord?.quote?.name" class="info-block">
      <span>
        <mat-icon class="info-icon">article</mat-icon>
      </span>
      <div *ngIf="eventRecord?.quote?.link">
        <a [href]="eventRecord.quote.link" target="_blank">
          <div class="link">
            {{ eventRecord.quote.name }}
          </div>
        </a>
      </div>
      <div *ngIf="!eventRecord?.quote?.link">
        {{ eventRecord.quote.name }}
      </div>
    </div>
    <div *ngIf="eventRecord?.warehouse?.name" class="info-block">
      <span *ngIf="eventRecord?.quote?.name" class="info-divider">
        /
      </span>
      <span>
        <mat-icon class="info-icon">warehouse</mat-icon>
      </span>
      {{ eventRecord.warehouse.name }}
    </div>
    <div *ngIf="eventRecord?.sheet?.name" class="info-block">
      <span *ngIf="eventRecord?.warehouse?.name || eventRecord?.quote?.name" class="info-divider">
        /
      </span>
      <mat-icon class="info-icon" *ngIf="eventRecord?.sheet?.type === 'RETURNSHEET'">login</mat-icon>
      <mat-icon class="info-icon" *ngIf="eventRecord?.sheet?.type === 'PULLSHEET'">logout</mat-icon>
      <div *ngIf="eventRecord?.sheet?.link">
        <a [href]="eventRecord.sheet.link" target="_blank">
          <div class="link">
            {{ eventRecord.sheet.name }}
          </div>
        </a>
      </div>
      <div *ngIf="!eventRecord?.sheet?.link">
        {{ eventRecord.sheet.name }}
      </div>
    </div>
  </div>
</div>
